import '../scss/social.scss';

function SocialButton() {
  return (
    <nav>
    <ul className='socialBox'>
     <li><a
          href="https://www.facebook.com/rameswaramdhiya"
          target="_blank"
          rel="noopener noreferrer" className="socialText"
        >
        <img src="./assets/icon-image/facebkicon.png" alt="FaceBook" className='infoSocialIcon'/></a>
     </li>
     <li><a
          href="https://twitter.com/rameswaramdhiya"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/twitter.png" alt="Twitter" className='infoSocialIcon'/></a>
     </li>
     <li><a
          href="https://www.instagram.com/rameswaramdhiya"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/insta.png" alt="instagram" className='infoSocialIcon'/></a>
    </li>
     <li hidden='hidden'><a
          href="https://www.linkedin.com/in/rameswaramdhiya/"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/linkedin.png" alt="linkedin" className='infoSocialIcon'/></a>
    </li>
    <li><a
          href="https://wa.me/+919840631753"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/waicons.png" alt="WhatsApp" className='infoSocialIcon'/></a>
    </li>
    <li><a
          href="https://www.youtube.com/@rameswaramdhiya"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/youtube.png" alt="youtube" className='infoSocialIcon'/></a>
    </li>
    </ul>
  </nav>
  )
}

export default SocialButton