import { mostVisitedPackages } from '../services/impImages';
import '../scss/service.scss';
//import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';

function ProductPage() {

  //let navigate=useNavigate();
 // const handleClick =  (e) => navigate('/contact'); //toast("Currently Myvisapriority.com doesn't provide online visa application to "+ e.target.name +", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");
  const slides=[
    {url:'assets/slider-main/bg10.jpg', head:'',title:'',subTitle:''},
  ];
  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
   <div className="body-inner" id="products">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='serviceHomeText'>
                <h1>Packages</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | Tour Packages
      </div>
        <ToastContainer style={{width:'100%'}}/>
<div className='mainContainerStyles'>
<Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" className='serviceImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
</div>
</section>
<section className="portfolio defaultMargin infoContainer" id="portfolio">
      <div className="title__container">
        <h2>Tour Packages</h2>
        <p>We have already serve amazing service for our customers.</p>
      </div>
      <div className="container">
      { mostVisitedPackages.map((portfolio)=>(
        <div className="design">
          <Link to={portfolio.id} style={{ textDecoration: "none" }}>
          <div className="portfolio__container">
          <div className="image">
                  <img src={portfolio.img} alt={portfolio.title} />
                </div>
                <h5 key={portfolio.id}>{portfolio.title}</h5>
                
          </div>
          </Link>
        </div>
         ))}
      </div>
    </section>

<section className="infoContainer" style={{background:"darkblue"}}>
  <div className="container">
    <div className="row">
        <div className="col-md-12">
          <div className="subscribe-call-to-acton">
              <h1 className='callUs'>Call Us +91 7010332075</h1>
          </div>
        </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default ProductPage