import '../scss/home.scss';
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import {  mostVisitedPackages, myBlogs } from '../services/impImages';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import{FaPhoneAlt } from 'react-icons/fa';

function HomePage() {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/#');//toast("Currently Myvisapriority.com doesn't provide online visa application to " + e.target.name + ", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");

  const slides = [
    {id:1, url: 'assets/slider-main/bg4_4.jpg' },
    { id:2,url: 'assets/slider-main/bg4_2.jpg' },
    { id:3,url: 'assets/slider-main/bg8.jpg' },
    { id:4,url: 'assets/slider-main/bg4.jpg' },
    { id:5,url: 'assets/slider-main/bg10.jpg' }
  ];

  const testimonials = [
    {
      id:1,
      name: "- Dr.Rajiv Ramnath",
      content:"Different travel service and prompt pick up and drop with safe operations. Great to see some people still like this..The various safari's provided all types of experiences, from very upscale camps to more primitive bush camps all expertly chosen for very specific reasons."
    },
    {
      id:2,
      name: "- Dr.Cindrella",
      content:"What a brilliant travel agency and drivers. Heart touching service and very attractive offers. we wish them !!!We would definitely use you again for future travel plans, in fact, you are currently planning our trip to India."
    },
    {
      id:3,
      name: "- Dr.John Joel",
      content:"Thank you for the fantastic trip to South Rameswaram Madurai, that you and your team planned for us. This was a trip that had not been high on our bucket list but close friends talked us into going with them. This trip turned out to be beyond our expectations all customized just for the four of us!! The planning details were perfect and the accommodations were superb."
    },
    {
      id:4,
      name: " - DR.Mega",
      content:
        "I just realized that we have been back from Rameswaram for 6 weeks now. I cannot believe we have been back that long. I am embarrassed that I took this long to get in touch with you. That said, I just wanted to tell you that we had an absolutely fantastic time on our trip. I can attest without hesitation the primary reason we had just had a great time was due to you and the care and attention you put into planning our trip."
    }
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let settings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let testimonialSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  
  return (
    <div className="body-inner" id="home">
      <section className="banner-carousel banner-carousel-1 mb-0">
        <div className='topHead'>
        <div className='topText'>
          <h6><i>"Rameswaram Dhiya Tours and Travels"</i></h6>
          <h1>PLAN YOUR TRIP WITH US TO RAMESWARAM</h1>
        </div>
      </div>
        <ToastContainer style={{ width: '100%' }} />
        <div className='containerStyles'>
        <Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" key={image.id} className='imgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
        </div>
      </section>

      <div className="gap-40"></div>
      <section id="facts" className="secFact" >
        <div className="container">
          <div className="row secFactsRow">
            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/fact4.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Car Rental</h3>
                <a href={`tel:${'+917010332075'}`} className='infoText'>
                <FaPhoneAlt className='infoIcon'/>
               <span id='lbl'> +91 7010332075</span> 
               </a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/fact1.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Hotel Rental</h3>
                <a href={`tel:${'+919345093335'}`} className='infoText'>
                <FaPhoneAlt className='infoIcon'/>
               <span id='lbl'> +91 9345093335</span> 
               </a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/fact2.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Pooja Booking</h3>
                <a href={`tel:${'+919345093335'}`} className='infoText'>
                <FaPhoneAlt className='infoIcon'/>
               <span id='lbl'> +91 9345093335</span> 
               </a>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/fact5.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Ticket Booking</h3>
                <a href={`tel:${'+919952736932'}`} className='infoText'>
                <FaPhoneAlt className='infoIcon'/>
               <span id='lbl'> +91 9952736932</span> 
               </a>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id="ts-features" className="infoContainer">
          <div className='serviceBox'>
            <div className="tsIntro">
              <h2 className="infoSubTitle">Dhiya Tours And Travels & Home Stay Welcomes You</h2>
            </div>
            <div className="row">
              <div className="col-md-12">
<p>Welcome to Rameshwaram, and warm regards from Dhiya Tours and Travels and Dhiya Home Stay A prolific travel service from the holly city Rameshwaram. A diverse range of vehicles serving 24X7 with safety, promptness and comfort as the primary point of operations will enthrall you.</p>
<p>Dhiya Tours and Travels, and Dhiya Home Stay is the leading travel service provider in the Tamilnadu. New Travel Lines has been in operation from many years and is leading the industry with Customer delight as the goal of the service. Usage of state of the art technologies in the operations has established a formidable reputation throughout the industry. Comfort, Safety, Reliability, Discipline, Guidance and Economy have been the guiding principles of Dhiya Tours and Travels.</p>
              </div>
             
            </div>
          </div>
      </section>

      <section className="infoContainer" style={{ backgroundColor: 'darkblue' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <h1 className='homeCallUs'> Call Us +91 7010332075</h1>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Section id="facts" className="infoContainer">
      <div className="container">
        <div className='blogItemBox'>
      <h2 className="quoteTitle">Popular Tour Packages</h2>
      <Slider {...settings}>
          {mostVisitedPackages.map((myBlog) => (
             
              <div className="blogBox" key={myBlog.id}>
               <Link to={'products/'+myBlog.id} style={{ textDecoration: "none" }}>
              <img loading="lazy"  src={myBlog.img} alt={myBlog.name} className='blogImage' />
                  <h5 className="blogTitle">{myBlog.name} </h5> 
                  </Link>
              </div>
             
          ))}
        </Slider>
        </div>
        
        </div>
      </Section>


      <Section className="infoContainer">
        <div className="container">
              <div className="homeVisaItemBox">
              <h2 className="quoteTitle">South Indian Attractions</h2>
              <div className='row'>
                {myBlogs.map((image) => (
                  <div className="col-lg-4 col-md-6 mb-5" key={image.id}>
                     <Link to={'otherplaces/'+image.id} style={{ textDecoration: "none" }}>
                    <Tooltip title={image.name.toUpperCase()} className="show">
                      <div className="visaItem">
                        <img loading="lazy" src={image.img} alt={image.name}  onClick={handleClick} />
                        <h5 className="blogTitle">{image.name} </h5>
                      </div>
                    </Tooltip>
                    </Link>
                  </div>
                ))}
                </div>
              </div>
        </div>
      </Section>
      
      <Section id="facts" className="infoContainer">
      <div className="container">
        <div className='quoteItem'>
      <h2 className="quoteTitle">Testimonials</h2>
      <Slider {...testimonialSettings}>
          {testimonials.map((testimonial) => (
            <div className="out" key={testimonial.id}>
              <div className="quoteText">
              {testimonial.content}
                  <div className="quoteFooter">{testimonial.name} </div>
              </div>
            </div>
          ))}
        </Slider>
        </div>
        
        </div>
      </Section>

      <section className="infoContainer" style={{ backgroundColor: 'darkblue' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <h1 className='homeCallUs'> For Ticket +91 9952736932</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      
  
    </div>
  )
}

export default HomePage;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};
  `;