import '../scss/about.scss';
import { Link } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import Slider from 'react-slick';

function AboutPage() {

  /* const myFunction=()=> {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  
  const myFunction1 =() => {
    var dots = document.getElementById("dots1");
    var moreText = document.getElementById("more1");
    var btnText = document.getElementById("myBtn1");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  
  const myFunction2=()=> {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  const myFunction3=()=> {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  } */

  const slides=[
    {url:'assets/slider-pages/img1.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img2.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img3.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img4.jpg', head:'',title:'',subTitle:''}
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const mainSlides=[
    {id:1,url:'assets/slider-main/bg9.jpg', head:'',title:'',subTitle:''},
  ];

const containerStyles={

  margin:'0 auto',
  objectFit: 'cover'
}


  return (
    <div  className="body-inner" id="about">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='aboutHomeText'>
                <h1>About</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | About
      </div>
       <div className='mainContainerStyles'>
            <Slider {...mainSettings}>
        {mainSlides.map((image) => (
                        <img loading="lazy" key={image.id} className='aboutImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
          </div>
      </section>
  <section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    <div className="row">
        <div className="col-lg-6">
        <h2 className="secMidTitle">Dhiya – Tours And Travels</h2>
          <p className='aboutBoxText'> Dhiya Tours and Travels is the reliable and loyal travelling and cab service provider operating from world famous tourist place Rameswaram - Ramnad district. Since Last two decades of service to thier valuable guests in way of friendly and pleasing approach. The backbone of this travel agency and the person behind this is Mr. Mareeswaran is well knowledged and skilled in driving and automobile mechanism. Also well known personality in Rameswaram and Ramnad district. Thus We are assuring and our promise to our clients for better quality service and their satisfaction. </p>
         
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div style={containerStyles} className="aboutImg">
             <ImageSlider slides={slides}/>
          </div>    
        </div>
    </div>
  </div>
</section>

<section className="infoContainer" style={{ backgroundColor: 'darkblue' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <h1 className='homeCallUs'> Call Us +91 7010332075</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      
<section id="info-container" className="infoContainer">
  <div className="container aboutSecBox">
    <div className="row">
    <div className="col-lg-4 col-md-6 mb-5 ">
    <div className='aboutMidBoxText'>
      <h3 className='boxTitle'>Resources</h3>
    <ul >
             <li>Rameshwaram Sightseens</li>
              <li>Tempo Travellers Hire</li>
              <li>Best Places to Visit Madurai</li>
              <li>Kanyakumari Sightseens</li>
              <li>Best Places to Visit Kerala</li>
              <li>Best Places to Visit Kodaikanal</li>
              <li>24/7 Car Rental Services</li>
              <li>Ticket Booking Service</li>
              <li>Top South Indian’s Destinations</li>
              <li>Budget Hotels in Rameswaram</li>
              <li>Most Popular Tour Packages</li>
             </ul>
          </div>  
        </div>
        <div className="col-lg-8 col-md-6 mb-5">
        <h3 className="secTitle">Why Choose Us</h3>
        <p className="aboutBoxText"><b>Safe Travel to become better :</b>  </p>
        <p className='aboutBoxText'> We always part in effort to be competitive by constant and continious innovation in our Services and travelling areas. The benefits to our customers are qualitative experience in Travel places and Cabs at the cheaper price.</p>
        <p className='aboutBoxText'>Our skilled drivers and supporting team has developed good relationshhip and always smiling services to our guests. We are always ready to serve with smiling face and working with the thought of " OUR GUESTS AND THEIR SAFETY IS VERY IMPORTANT "</p>
          
          <p className='aboutBoxText'> <b>Serving Principles : </b> </p>
          <p className='aboutBoxText'>A commitment is to be made only if it can be fulfilled and what cannot be fulfilled must never be committed.</p>
          <p className='aboutBoxText'>Best service has to be spelt out to customer in strong clear and unambiguous terms.</p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default AboutPage