import '../scss/navbar.scss';
import { Link } from 'react-router-dom';
import{FaBars,FaTimes,FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { CiBank } from "react-icons/ci";
import { useState } from 'react';

function NavBar() {
  const [icon,SetIcon]=useState(false);

  const handleClick=()=>{
    SetIcon(!icon);
   };

   const closeSideDrawer=()=>{
    SetIcon(false);
   };

  return (
    <>
    <div className='topper'>
  <ul className='infoBox'>
  <li>
      <Link to={`/paymentoption`}  className='infoText'>
        <CiBank className='infoIcon'/>
        <span id='lbl'> Payment Option</span>
        </Link>
    </li>
    <li>
      <a href={`mailto:${'devieswar9855@gmail.com'}`}  className='infoText'>
        <FaEnvelope className='infoIcon'/>
        <span id='lbl'> devieswar9855@gmail.com</span>
        </a>
    </li>
    <li>
      <a href={`tel:${'+917010332075'}`} className='infoText'>
        <FaPhoneAlt className='infoIcon'/>
        <span id='lbl'> +91 7010332075</span>   
        </a>
    </li>
    <li>    
      <a
          href="https://wa.me/+919840631753"
          target="_blank"
          rel="noopener noreferrer"  className="infoText"
        >
        <img src="./assets/icon-image/wappicons.png" alt="Rameswaram Dhiya" className='infoWaIcon'/></a>
    </li>
  </ul>
</div>
    <nav className='navBar'>
    <Link className="navLogo" data-no-turbolink="true" to="/" onClick={closeSideDrawer} > <img src="./assets/logo.png" alt="Rameswaram Dhiya" className='logoImg' /></Link>
    <div className="menuIcon" onClick={handleClick}>
      {icon?<FaTimes className='faTimes'/>:<FaBars className='faBars'/>}
    </div>
      <ul className={icon? 'navMenu active' :'navMenu'}>
        <li>
      <Link className="navLink" data-no-turbolink="true" to="/" onClick={closeSideDrawer}>Home</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/about" onClick={closeSideDrawer}>About</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/products" onClick={closeSideDrawer}>Tour Packages</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/services" onClick={closeSideDrawer}>Hotels</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/places" onClick={closeSideDrawer}>Places</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/gallery" onClick={closeSideDrawer}>Gallery</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>Contact us</Link>
      </li>
      <li>
      <Link className="navLink applyNow" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>BookNow</Link>
      </li>
      </ul>
    </nav>

    </>
  )
}

export default NavBar;