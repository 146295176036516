import '../scss/service.scss';
//import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import ImageSlider from '../ImageSlider';

function ServicePage() {

  //let navigate=useNavigate();
  //const handleClick =  (e) => navigate('/contact'); //toast("Currently Myvisapriority.com doesn't provide online visa application to "+ e.target.name +", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");
  const slides=[
    {url:'assets/images/single.jpg', head:'',title:'',subTitle:''},
    {url:'assets/images/double.jpg', head:'',title:'',subTitle:''},
    {url:'assets/images/trible.jpg', head:'',title:'',subTitle:''},
    {url:'assets/images/four.jpg', head:'',title:'',subTitle:''}
  ];

  const mainSlides=[
    {url:'assets/slider-main/bg4.jpg', head:'',title:'',subTitle:''},
  ];
  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const containerStyles={

    margin:'0 auto',
    objectFit: 'cover'
  }

  return (
   <div className="body-inner" id="services">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='serviceHomeText'>
                <h1>Hotels</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | Hotels
      </div>
        <ToastContainer style={{width:'100%'}}/>
<div className='mainContainerStyles'>
<Slider {...mainSettings}>
        {mainSlides.map((image) => (
                        <img loading="lazy" className='serviceImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
</div>
</section>

<section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    <div className="row">
        <div className="col-lg-6">
        <h2 className="secMidTitle">Hotels</h2>
          <p className='aboutBoxText'>We provides you the best quality car rental services for all your travel needs. We have a wide range of well maintained vehicles. We at Ram sethu tours and travels organize tour services to variety of places in and around Rameswaram. Be it tour of the famous temples like Rameswaram, Madurai, Kanyakumari, Kerala, Tanjore and more. And also Wish to relax in Hill stations like Ooty or Kodai or Yercaud, or relax in the beach side resorts in Pondycherry or Mahabalipuram or ECR. We strive for your best comfort. RamSethu Tours and Travels offers Travels services for tourists. We Offers accommodation for Family, Groups and Single with economical rates. </p>
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div style={containerStyles}  className="aboutImg">
             <ImageSlider slides={slides}/>
          </div>    
        </div>
    </div>
  </div>
</section>

<section className="infoContainer" style={{background:"darkblue"}}>
  <div className="container">
    <div className="row">
        <div className="col-md-12">
          <div className="subscribe-call-to-acton">
              <h1 className='callUs'>Call Us +91 9345093335</h1>
          </div>
        </div>
    </div>
  </div>
</section>

 <section id="main-container" className="infoContainer">
 <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    <div className="row">
    <div className="col-lg-6 mt-5 mt-lg-0">
    <div className='aboutMidBoxText'>
      <h3 className='boxTitle'>Resources</h3>
    <ul >
             <li>Rameshwaram Sightseens</li>
              <li>Tempo Travellers Hire</li>
              <li>Best Places to Visit Madurai</li>
              <li>Kanyakumari Sightseens</li>
              <li>Best Places to Visit Kerala</li>
              <li>Best Places to Visit Kodaikanal</li>
              <li>24/7 Car Rental Services</li>
              <li>Ticket Booking Service</li>
              <li>Top South Indian’s Destinations</li>
              <li>Budget Hotels in Rameswaram</li>
              <li>Most Popular Tour Packages</li>
             </ul>
          </div>     
        </div>
        <div className="col-lg-6">
          <p className='aboutBoxText'> Cancellation Policy : </p>
          <ul>
          <li>25% if cancelled four days in advance.</li>
          <li>50% if cancelled two or three days in advance.</li>
          <li>50% if few of the total booked rooms are cancelled.</li>
          <li>100% if cancelled one day prior to booking or on the same day.</li>
          </ul>
          <p className='aboutBoxText'>For Groups / for Special Celebration Dates (24th, 25th, 30th & 31st of December & Long weekends) </p>
          <ul>
          <li>25% if cancelled forty five days in advance.</li>
          <li>50% if cancelled thirty days in advance.</li>
          <li>50% if few of the total booked rooms are cancelled.</li>
          <li>100% if cancelled fifteen days in advance or later.</li>
          </ul>
          <p className='aboutBoxText'>General Rules </p>
          <ul>
          <li>Only one postponement / preponement is permitted before 15 days</li>
          <li>subject to availability of accommodation</li>
          <li>After availing one postponement or preponement no cancellation is permitted.</li>
          </ul>
        </div>
       
    </div>
  </div>
</section>


    </div>
  )
}

export default ServicePage